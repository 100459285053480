
import React, { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import checkAuth from './app/auth';
import initializeApp from './app/init';
import { QueryClient } from '@tanstack/react-query';
import { CartProvider, MedusaProvider } from "medusa-react"

// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const Register = lazy(() => import('./pages/Register'))
const Documentation = lazy(() => import('./pages/Documentation'))
const Printer = lazy(() => import('./pages/protected/Printer'))
const PrinterInvoice = lazy(() => import('./pages/protected/Printer-Invoice'))
const PrinterOfficeDelivery = lazy(() => import('./pages/protected/Printer-Office-Delivery'))
const PrinterCustomerDelivery = lazy(() => import('./pages/protected/Printer-Customer-Delivery'))
const PrinterAll = lazy(() => import('./pages/protected/Printer-All'))
const PrinterAll3 = lazy(() => import('./pages/protected/Printer-All3'))
const PrinterAll4 = lazy(() => import('./pages/protected/Printer-All4'))
const PrinterQuote = lazy(() => import('./pages/protected/Printer-Quote'))
const MultiPay = lazy(() => import('./components/MultiPay'))
const PricesPrint = lazy(() => import('./pages/protected/Prices-print'))
const PricesChange = lazy(() => import('./pages/protected/Prices-change'))
const PricePrintCat = lazy(() => import('./pages/protected/Print-prices-cat'))


const queryClient = new QueryClient()

// Initializing different libraries
initializeApp()


// Check for login and initialize axios
const token = checkAuth()


function App() {

  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false)
  }, [])


const APITOKEN = "QJwvhucdz4jV75OrtsBjZKaybmEry4jV"

//console.log("MEDUSA_BACKEND_URL", process.env.REACT_APP_MEDUSA_BACKEND_URL)
//console.log("MEDUSA_API_KEY", process.env.REACT_APP_MEDUSA_API_KEY)


  return (
    <>
    <MedusaProvider 
    queryClientProviderProps={{ client: queryClient }}

    baseUrl={process.env.REACT_APP_MEDUSA_BACKEND_URL}
    apiKey={process.env.REACT_APP_MEDUSA_API_KEY} > 
    
    {console.log("api key ",process.env.REACT_APP_MEDUSA_API_KEY)}

      <CartProvider> 
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/documentation" element={<Documentation />} />

          <Route path="/app/printer-all/:id" element={<PrinterAll />}  />
          <Route path="/app/printer-all3/:id" element={<PrinterAll3 />}  />
          <Route path="/app/printer-all4/:id" element={<PrinterAll4 />}  />

          
          <Route path="/app/printer-invoice/:id" element={<PrinterInvoice />}  />
          <Route path="/app/printer-customer-delivery/:id" element={<PrinterCustomerDelivery />}  />
          <Route path="/app/printer-office-delivery/:id" element={<PrinterOfficeDelivery />}  />

          <Route path="/app/printer-quote/:id" element={<PrinterQuote />}  />
          <Route path="/app/multipay/o/:id" element={<MultiPay />} />
          <Route path="/app/Prices-print-cat/:id" element={<PricePrintCat />} />




         
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />

          <Route path="*" element={<Navigate to={token ? "/app/dashboard" : "/login"} replace />}/>

        </Routes>
      </Router>
      </CartProvider>
      </MedusaProvider>
    </>
  )
}

export default App
